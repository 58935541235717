<template>
    <div class="hidden-content">
        <div :class="isRevealed ? '--is-revealed' : ''" class="wrapper">
            <slot></slot>
        </div>

        <Button
            @click="onReadMoreClick"
            variant="tertiary"
            class="about-revealer"
            :class="isRevealed ? '--is-revealed' : ''"
        >
            {{
                isRevealed ? $t('read less', 1, { locale: pageLanguage }) : $t('read more', 1, { locale: pageLanguage })
            }}
        </Button>
    </div>
</template>

<script setup>
const isRevealed = ref(false);
const onReadMoreClick = () => (isRevealed.value = !isRevealed.value);
const { pageLanguage } = usePageDataStore();
</script>

<style lang="scss" scoped>
.wrapper {
    height: auto;
    max-height: 30rem;
    overflow: hidden;
    position: relative;
    mask-image: linear-gradient(180deg, #000 20%, transparent);

    &.--is-revealed {
        max-height: unset;
        min-height: fit-content;
        overflow: visible;
        mask-image: none;
    }
}

.about-revealer.--is-revealed {
    margin-top: 3.5rem;
}
</style>
